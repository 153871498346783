import React, { useState } from "react";
import "../css/Navbar.css";
import logo from "../images/logo.png";

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const scrollToContact = () => {
    const contactSection = document.getElementById("contact-section");
    if (contactSection) {
      contactSection.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const multi = () => {
    closeMenu();
    scrollToContact();
  }

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="logo">
          <a href="/">
            <img src={logo} alt="Neat Services Logo" />
          </a>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <i className={menuOpen ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
        <ul className={menuOpen ? "nav-links open" : "nav-links"}>
          <li>
            <a onClick={closeMenu} href="/">
              Home
            </a>
          </li>
          <li>
            <a onClick={closeMenu} href="/about">
              About us
            </a>
          </li>
          <li>
            <p
              className="contact"
              style={{ cursor: "pointer" }}
              onClick={multi}
            >
              Contact
            </p>
          </li>
        </ul>
        <div className="social-icons">
          <a href="/">
            <i className="fab fa-facebook"></i>
          </a>
          <a href="/">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="/">
            <i className="fab fa-tiktok"></i>
          </a>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
