import React, { useState } from "react";
import "../css/Home.css";
import siding from "../images/siding.jpg";
import siding1 from "../images/siding1.png";
import siding2 from "../images/siding3.jpg";
import roofing from "../images/roofing1.png";
import roofing1 from "../images/roofing2.jpeg";
import roofing2 from "../images/roofing3.jpg";
import EstimateForm from "./EstimateForm";

function Home() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(false);

  const images = [roofing, siding2, roofing1, siding1, roofing2, siding];

  const openModal = (index) => {
    setSelectedImage(images[index]);
    setCurrentIndex(index);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const nextImage = () => {
    setFade(true); 
    setTimeout(() => {
      setFade(false); 
      setCurrentIndex((currentIndex + 1) % images.length);
      setSelectedImage(images[(currentIndex + 1) % images.length]);
    }, 300);
  };

  const prevImage = () => {
    setFade(true); 
    setTimeout(() => {
      setFade(false); 
      const newIndex = (currentIndex - 1 + images.length) % images.length;
      setCurrentIndex(newIndex);
      setSelectedImage(images[newIndex]);
    }, 300);
  };

  return (
    <>
      <EstimateForm />
      <div className="projects-gallery">
        <div className="gallery-header">
          <div className="home-projects-header">
            <h4>Our Projects</h4>
            <h1>CHECK OUR PROJECTS GALLERY</h1>
            <p>
              <p>
                From siding to roofing, we’re ready to transform your home’s
                appearance entirely.
                <br />
                Our gallery showcases the diversity and quality of projects
                we’ve completed, reflecting our dedication to excellence.
                <br />
                Browse through to see how we’ve helped homeowners achieve their
                dream look.
              </p>
            </p>
          </div>
        </div>
        <div className="gallery-grid">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Project ${index + 1}`}
              onClick={() => openModal(index)}
            />
          ))}
        </div>

        {selectedImage && (
          <div className="modal" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <button className="close-button" onClick={closeModal}>
                &times;
              </button>
              <div className="modal-header">
                <span className="image-index">
                  {currentIndex + 1} / {images.length}
                </span>
              </div>
              <button className="prev-button" onClick={prevImage}>
                &#8249;
              </button>
              <img
                className={`modal-image ${fade ? "fade" : ""}`}
                src={selectedImage}
                alt="Selected project"
              />
              <button className="next-button" onClick={nextImage}>
                &#8250;
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Home;
