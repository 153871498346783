import "./App.css";
import ContactInfo from "./components/ContactInfo";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import { Outlet } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Outlet />
      <div id="contact-section">
      <ContactInfo />
      </div>
      <Footer />
    </div>
  );
}

export default App;
