import React from "react";
import "../css/About.css";
import roofing from "../images/roofing.jpeg";

function About() {
  return (
    <div>
      <div className="container1">
        <p className="text1">A COMPANY FOUNDED BY A FAMILY TO</p>
        <h1 className="text2">SERVE FAMILIES</h1>
        <p className="text3">
          We are committed to delivering an exceptional guide to our customers
          and <br />
          offering unamtched teamwork at your service.
        </p>
      </div>
      <div className="seperator">
        <img className="Roofing" src={roofing} alt="Roofing"></img>
      </div>
      <div className="container2">
        <div className="container-explanations">
          <div className="explanation">
            <h5 className="expanation-title">Why We Exist (Why)</h5>
            <hr className="line" />
            <p>
              At Sara Jaga, we prioritize building strong family connections and
              crafting homes that exude warmth, comfort, and elegance. We
              believe every family deserves a welcoming space to thrive, dream,
              and create lifelong memories. Inspired by our own family journey,
              our mission is to transform every house into a haven of security,
              style, and joy. Discover how Sara Jaga can help you design a home
              that truly feels like yours.
            </p>
          </div>
          <div className="explanation">
            <h5 className="expanation-title">How We Do It (How)</h5>
            <hr className="line" />
            <p>
              Our company is built on the core values of trust, excellence, and
              dedication, ensuring a seamless and reliable experience for all
              your home improvement needs. Understanding the significance of
              every home project, we emphasize integrity, transparency, and an
              unwavering commitment to quality. From personalized consultations
              to the final touches, our expert team works closely with you to
              bring your vision to life. Leveraging cutting-edge technologies
              and premium materials, we deliver results that are both durable
              and visually exceptional. Choose us to transform your home with
              unparalleled craftsmanship and care.
            </p>
          </div>
          <div className="explanation">
            <h5 className="expanation-title">What We Do (What)</h5>
            <hr className="line" />
            <p>
              At Sara Jaga, we specialize in premium roofing and siding
              services, providing expert solutions to improve your home's
              durability, style, and curb appeal. What began as a humble family
              vision has flourished into a trusted industry name, catering to
              the diverse needs of our valued clients. With a strong commitment
              to quality craftsmanship and customer satisfaction, we transform
              houses into homes you can be proud of. Discover how Sara Jaga can
              enhance your home’s beauty and resilience with our roofing and
              siding expertise.
            </p>
          </div>
          <div className="explanation">
            <h5 className="expanation-title">Our Invitation</h5>
            <hr className="line" />
            <p>
              Join our growing family of satisfied customers at Sara Jaga, where
              we do more than just transform your home—we take you on a journey
              of meaningful change. Every project we undertake is designed to
              reflect your family's unique stories, dreams, and aspirations. At
              Sara Jaga, we don’t just build houses; we create spaces that serve
              as the foundation for your future, where every corner feels like
              home. Let us help you craft a home that truly tells your story.
            </p>
          </div>
        </div>
      </div>
      <div className="container-connectWithUs">
        <h1 className="title-connect">Connect With Us</h1>
        <p className="p-connect">
          Explore how we can turn your home improvement dreams into reality.
          Reach out to SaraJaga today and <br /> take the first step toward
          creating the home you’ve always envisioned.
        </p>

        <a className="button-connect" href="tel:9172429473​">
          CALL US
        </a>
      </div>
    </div>
  );
}

export default About;
