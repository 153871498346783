import React from "react";
import "../css/Footer.css";
import logo from "../images/logo.png";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section logo-section">
          <div className="logo">
            <a href="/">
              <img src={logo} alt="SaraJaga Logo" />
            </a>
          </div>
        </div>

        <div className="footer-section services-section">
          <h3 style={{ fontSize: 19.5 }}>OUR SERVICES</h3>
          <ul>
            <li style={{ fontSize: 13 }}>SIDING</li>
            <li style={{ fontSize: 13 }}>ROOFING</li>
          </ul>
        </div>

        <div className="footer-section schedule-section">
          <h3 style={{ fontSize: 19.5 }}>SCHEDULE</h3>
          <p style={{ fontSize: 13 }}>Monday to Friday</p>
          <p style={{ fontSize: 13 }}>9:00am to 5:00pm</p>
        </div>

        <div className="footer-section contact-section">
          <h3 style={{ fontSize: 19.5 }}>GET IN TOUCH</h3>
          <p style={{ fontSize: 13 }}>
            <i className="fas fa-map-marker-alt"></i>
            <a href="https://www.google.com/maps/place/28-07+Jackson+Ave,+Long+Island+City,+NY+11101,+USA/@40.7488988,-73.9414097,17z/data=!3m1!4b1!4m6!3m5!1s0x89c258d5803dbaf9:0xaadf2f1b2fd2797c!8m2!3d40.7488948!4d-73.9388294!16s%2Fg%2F11cphrm01j?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D">
              28-07 Jackson Ave Long Island City, NY 11101
            </a>
          </p>
          <p style={{ fontSize: 13 }}>
            <i className="fas fa-phone" href="tel:9172429473​"></i>{" "}
            <a href="tel:9172429473​">+1 (917) 242 9473</a>
          </p>
          <p style={{ fontSize: 13 }}>
            <i className="fas fa-envelope"></i>{" "}
            <a href="mailto:sarajagacorp@gmail.com">sarajagacorp@gmail.com</a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
