import React from "react";
import "../css/EstimateForm.css";

function EstimateForm() {
  return (
    <div className="contractors-banner">
      <div className="banner-overlay">
        <div className="banner-text">
          <h1>NEW YORK CITY CONTRACTORS</h1>
          <p>EXPERT RENOVATIONS, RELIABLE SOLUTIONS, UNMATCHED QUALITY</p>
        </div>
        <div className="form-container">
          <h2>GET YOUR ESTIMATE NOW</h2>
          <form className="estimate-form">
            <input type="text" placeholder="Name" />
            <input type="email" placeholder="Email Address" required />
            <input type="tel" placeholder="Phone Number" required />
            <input type="service" placeholder="Service" required />
            <button type="submit">GET QUOTE</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EstimateForm;
